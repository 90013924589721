import React from 'react';
import './style.css'
//import logo from '../../assets/footer/logo.svg'
import image from '../../assets/contact/image.png'
import ScrollAnimation from 'react-animate-on-scroll';
import axios from 'axios';


class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            lastname: '',
            time: true,
            interes: null,
            phone: 0,
            mail: '',
            comment: '',
            send: true,
            screenWidth: window.innerWidth,
        };

        this.handleResize = this.handleResize.bind(this);
    }

    contactForm = (name, mail, phone, interes, comment) => {
        const formData = new FormData();
        name && formData.append("name", name);
        mail && formData.append("email", mail);
        phone && formData.append("phone", phone);
        interes && formData.append("options", interes);
        comment && formData.append("comments", comment);
        axios.post(
            'https://api-ecommerce.devpackgroup.com/api/v1/send_email_alaia/',
            formData,
            {
                headers: {
                    // "Authorization": "Token " + this.userToken,
                }
            }
        ).then(function (response) {
            this.setState({
                send: false,
                name: '',
                lastname: '',
                interes: null,
                phone: 0,
                mail: '',
                comment: '',
            })
            this.handleAnimation()
        }.bind(this)).catch(function (error) {

        }.bind(this));
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({
            screenWidth: window.innerWidth,
        });
    }

    handleAnimation = () => {
        setTimeout(() => {
            this.setState({ send: !this.state.send }, () => {
                this.forceUpdate();
            });
        }, 3500);
    };

    render() {
        return (
            <>
                {

                    <div style={{ backgroundColor: '#ffffff', zIndex: 0 }}>

                        {
                            this.state.screenWidth >= 600 ?
                                <div className=' '>
                                    <div className='contactDiv '>

                                        <div className='imageContact'>
                                            <img className='imageContactI' src={image} alt='everoContact' />
                                        </div>
                                        <div style={{ width: '50vw' }}>
                                            <div className='TitleContact'>
                                                <p>
                                                    BIENVENIDO A UN
                                                    <br />
                                                    NUEVO ESTILO DE VIDA
                                                </p>
                                            </div>
                                            {this.state.send &&
                                                <>
                                                    <div className='fullscreenSecond'>
                                                        <div className='secondRow' >
                                                            <div >
                                                                <input
                                                                    className='inputContactFirst'
                                                                    placeholder='Nombre'
                                                                    onChange={(e) => this.setState({
                                                                        name: e.target.value
                                                                    })}
                                                                />
                                                                <input
                                                                    className='inputContactFirstSecond'
                                                                    placeholder='Télefono'
                                                                    type='number'
                                                                    onkeydown="return false"
                                                                    onChange={(e) => this.setState({
                                                                        phone: e.target.value
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='fullscreenSecond'>
                                                        <div className='thirdRow' >
                                                            <div >
                                                                <input
                                                                    className='inputContactSecond'
                                                                    placeholder='Correo electrónico'
                                                                    onChange={(e) => this.setState({
                                                                        mail: e.target.value
                                                                    })}
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='fullscreenSecond'>
                                                        <div className='thirdRow' >
                                                            <div >
                                                                <select
                                                                    className='inputContactSecond'
                                                                    placeholder='Modelo de interés'
                                                                    onChange={(e) => this.setState({
                                                                        interes: e.target.value
                                                                    })}
                                                                    value={this.state.interes || ''}
                                                                    >
                                                                        <option value="" disabled>Modelo de interés</option>
                                                                        <option>Cardiel</option>
                                                                        <option >Baikal</option>
                                                                        <option >Aral</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='fullscreenSecond'>
                                                        <div className='lastRow' >
                                                            <div >
                                                                <textarea
                                                                    className='inputContactSecond'
                                                                    placeholder='Comentarios'
                                                                    onChange={(e) => this.setState({
                                                                        comment: e.target.value
                                                                    })}
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className='fullscreenbutton'>
                                                <div className='firtsRowbutton  '>
                                                    <div>
                                                        {this.state.send ? <button
                                                            onClick={() => this.contactForm(this.state.name, this.state.mail, this.state.phone, this.state.interes, this.state.comment)}
                                                            className='buttonContact'>
                                                            <p className='buttonContactP'>
                                                                Enviar
                                                            </p>
                                                        </button>
                                                            :
                                                            <button
                                                                onClick={() => this.contactForm(this.state.name, this.state.mail, this.state.phone, this.state.interes, this.state.comment)}
                                                                className='buttonContact'>
                                                                <p className='buttonContactP'>
                                                                    Enviado
                                                                </p>
                                                            </button>
                                                        }
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className=''>
                                    <div className='contactDiv  '>

                                        <div className='imageContact'>
                                            <img className='imageContactI' src={image} alt='everoContact' />
                                        </div>
                                        <div style={{ width: '100vw' }}>
                                            <div className='TitleContact'>
                                                <p>
                                                    BIENVENIDO A UN
                                                    <br />
                                                    NUEVO ESTILO DE VIDA
                                                </p>
                                            </div>
                                            {this.state.send &&
                                                <>
                                                    <div className='fullscreenSecond'>
                                                        <div className='secondRow' >
                                                            <div >
                                                                <input
                                                                    className='inputContactFirst'
                                                                    placeholder='Nombre'
                                                                    onChange={(e) => this.setState({
                                                                        name: e.target.value
                                                                    })}
                                                                />
                                                                <input
                                                                    className='inputContactFirstSecond'
                                                                    placeholder='Télefono'
                                                                    type='number'
                                                                    onkeydown="return false"
                                                                    onChange={(e) => this.setState({
                                                                        phone: e.target.value
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='fullscreenSecond'>
                                                        <div className='thirdRow' >
                                                            <div >
                                                                <input
                                                                    className='inputContactSecond'
                                                                    placeholder='Correo electrónico'
                                                                    onChange={(e) => this.setState({
                                                                        mail: e.target.value
                                                                    })}
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            <div className='fullscreenSecond'>
                                                <div className='thirdRow' >
                                                    <div >
                                                        <select
                                                            className='inputContactSecond'
                                                            placeholder='Modelo de interés'
                                                            onChange={(e) => this.setState({
                                                                interes: e.target.value
                                                            })}
                                                            value={this.state.interes || ''}
                                                        >
                                                            <option value="" disabled>Modelo de interés</option>
                                                            <option>Cardiel</option>
                                                            <option >Baikal</option>
                                                            <option >Aral</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>


                                            <div className='fullscreenSecond'>
                                                <div className='lastRow' >
                                                    <div >
                                                        <textarea
                                                            className='inputContactSecond'
                                                            placeholder='Comentarios'
                                                            onChange={(e) => this.setState({
                                                                comment: e.target.value
                                                            })}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='fullscreenbutton'>
                                                <div className='firtsRowbutton  '>
                                                    <div>
                                                        {this.state.send ? <button
                                                            onClick={() => this.contactForm(this.state.name, this.state.mail, this.state.phone, this.state.interes, this.state.comment)}
                                                            className='buttonContact'>
                                                            <p className='buttonContactP'>
                                                                Enviar
                                                            </p>
                                                        </button>
                                                            :
                                                            <button
                                                                onClick={() => this.contactForm(this.state.name, this.state.mail, this.state.phone, this.state.interes, this.state.comment)}
                                                                className='buttonContact'>
                                                                <p className='buttonContactP'>
                                                                    Enviado
                                                                </p>
                                                            </button>
                                                        }
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                        }

                    </div>
                }
            </>
        );
    }
}

export default Contact;
