import React from 'react';
import './style.css'
import { Button } from 'reactstrap';
import video from '../../assets/video/evero.mp4'
import image1 from '../../assets/home/image1.png'
import apartament from '../../assets/home/apartamento.png'
import image2 from '../../assets/home/image2.png'
import image3 from '../../assets/home/casa.png'
import image4 from '../../assets/ALAIA_Presentacióndigital.pdf'
import { Parallax, Background } from "react-parallax";
import ScrollAnimation from 'react-animate-on-scroll';

const pathname = window.location.pathname
class HomeFull extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrollY: 0,
            waiting: true,
        };

    }

    handleAnimation = () => {
        this.setState({ waiting: true });
        setTimeout(() => {
            this.setState({ waiting: false });
        }, 4500); // Espera medio segundo antes de regresar al estado original
    };

    componentDidMount() {
        this.handleAnimation()
    }



    render() {
        const insideStyles = {
            background: "white",
            padding: 20,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)"
        };
        return (
            <div>
                <div >
                    {/* <div className='HomeFullFirst'   >
                        <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                            <h1 className='title '>
                                UN NUEVO
                                <br />
                                DESTINO
                            </h1>
                        </ScrollAnimation>
                        <div delay={100} animateIn="fadeIn" animateOnce={true} className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                            <h1 className='title '>
                                UN NUEVO
                                <br />
                                DESTINO
                            </h1>
                        </div>
                        <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                            <p className='text'>
                                Un hogar que combina con tu estilo de vida, donde cada detalle está cuidadosamente elaborado para lograr un diseño único.
                            </p>
                        </ScrollAnimation>

                        <div delay={100} animateIn="fadeIn" animateOnce={true} className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                            <p className='text'>
                            Un hogar que combina con tu estilo de vida, donde cada detalle está cuidadosamente elaborado para lograr un diseño único.
                            </p>
                        </div>

                        <div className='divImagesFirst'>

                            <div className='divoneImage'>
                                <Parallax bgImage={image4} strength={200}>
                                    <div className='flexImage' style={{ height: 980 }} >
                                        <a href='/house' className='buttonFirst removeStyleHome'>
                                            <p className='textFirst'>
                                                Casas
                                            </p>
                                        </a>
                                    </div>
                                </Parallax>
                            </div>

                            <div className='lineWhite'>

                            </div>
                            <div className='divtwoImage'>

                                <Parallax bgImage={apartament} strength={200}>
                                    <div className='lineWhite'>

                                    </div>
                                    <div style={{ height: 980 }}>
                                        <a a href="/apartamentos" className='buttonSecond removeStyleHome'>
                                            <p className='textFirst'>
                                                Apartamentos
                                            </p>
                                        </a>
                                    </div>
                                </Parallax>
                            </div>
                        </div>
                        
                    </div> */}
                    <div className='parallaxphone d-none d-lg-block'>
                        <Parallax bgImage={image3} strength={200}>
                            <div className='HomeFullSecondIMAGE' style={{ height: 1380 }}>

                                <div className='HomeFull'  >
                                    <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                                        <h1 className='title '>
                                            UN ESPACIO ÚNICO AL QUE
                                            <br />
                                            PODRÁS LLAMAR HOGAR
                                        </h1>
                                    </ScrollAnimation>
                                    <div className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                                        <h1 className='title '>
                                            UN ESPACIO ÚNICO AL QUE
                                            <br />
                                            PODRÁS LLAMAR HOGAR
                                        </h1>
                                    </div>
                                    <ScrollAnimation style={{ textAlign: '-webkit-center' }} delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                                        <p className='text'>
                                            En Alaia podrás vivir un estilo de vida extraordinario en donde cada detalle fue pensado para
                                            disfrutar momentos de conexión familiar.
                                        </p>
                                    </ScrollAnimation>

                                    <div style={{ textAlign: '-webkit-center' }} className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                                        <p className='text'>
                                            En Alaia podrás vivir un estilo de vida extraordinario en donde cada detalle fue pensado para
                                            disfrutar momentos de conexión familiar.                                        </p>
                                    </div>

                                    <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                                        <a href='/apartamentos' className='removeStyleHome'>
                                            <Button className='buttonHouse'>
                                                Conoce más
                                            </Button>
                                        </a>
                                    </ScrollAnimation>
                                    <div className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                                        <a href='/apartamentos' className='removeStyleHome'>
                                            <Button className='buttonHouse'>
                                                Conoce más
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Parallax>
                    </div>
                    <div className='parallaxphone d-block d-lg-none'>
                        {/*  <Parallax bgImage={image3} strength={200}> */}
                        <div className='HomeFullSecondIMAGE' /* style={{ height: 980 }} */>
                            <div className='HomeFull'  >
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                                    <h1 className='title '>
                                        UN ESPACIO ÚNICO AL QUE
                                        <br />
                                        PODRÁS LLAMAR HOGAR
                                    </h1>
                                </ScrollAnimation>
                                <div className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                                    <h1 className='title '>
                                        UN ESPACIO ÚNICO AL QUE

                                        PODRÁS LLAMAR HOGAR
                                    </h1>
                                </div>
                                <ScrollAnimation style={{ textAlign: '-webkit-center' }} delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                                    <p className='text'>
                                        En Alaia podrás vivir un estilo de vida extraordinario en donde cada detalle fue pensado para
                                        disfrutar momentos de conexión familiar.
                                    </p>
                                </ScrollAnimation>

                                <div style={{ textAlign: '-webkit-center' }} className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                                    <p className='text'>
                                        En Alaia podrás vivir un estilo de vida extraordinario en donde cada detalle fue pensado para
                                        disfrutar momentos de conexión familiar.                                        </p>
                                </div>

                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                                    <a href='/apartamentos' className='removeStyleHome'>
                                        <Button className='buttonHouse'>
                                            Conoce más
                                        </Button>
                                    </a>
                                </ScrollAnimation>
                                <div className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                                    <a href='/apartamentos' className='removeStyleHome'>
                                        <Button className='buttonHouse'>
                                            Conoce más
                                        </Button>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <img
                                    /* onClick={() => this.handleClick()} */
                                    className='imageArrowHsome' src={image3} alt="Flecha hacia abajo" />
                            </div>
                        </div>
                        {/* </Parallax> */}
                    </div>


                    <div className='parallaxphone d-none d-lg-block'>
                        <Parallax bgImage={image1} strength={200}>
                            <div className='HomeFullSecondIMAGE' style={{ height: 1380 }}>

                                <div className='HomeFullSecond'  >
                                    <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                                        <h1 className='title '>
                                            BIENVENIDO AL DINÁMICO
                                            <br />
                                            VECINDARIO DE EL FRUTAL
                                        </h1>
                                    </ScrollAnimation>
                                    <div className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                                        <h1 className='title '>
                                            BIENVENIDO AL DINÁMICO
                                            <br />
                                            VECINDARIO DE EL FRUTAL
                                        </h1>
                                    </div>
                                    <ScrollAnimation style={{ textAlign: '-webkit-center' }} delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                                        <p className='text'>
                                            Rodeada de áreas comerciales, condominios residenciales y colegios, Alaia cuenta con una ubicación
                                            privilegiada a pocos metros del Complejo Comercial El Frutal
                                        </p>
                                    </ScrollAnimation>

                                    <div style={{ textAlign: '-webkit-center' }} className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                                        <p className='text'>
                                            Rodeada de áreas comerciales, condominios residenciales y colegios, Alaia cuenta con una ubicación
                                            privilegiada a pocos metros del Complejo Comercial El Frutal
                                        </p>
                                    </div>
                                    <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                                        <a href='/ubicacion' className='removeStyleHomeApto'>
                                            <Button className='buttonApto'>
                                                Conoce más
                                            </Button>
                                        </a>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </Parallax>
                    </div>
                    <div className='parallaxphone d-block d-lg-none'>
                        {/* <Parallax bgImage={image1} strength={200}> */}
                        <div className='HomeFullSecondIMAGE' /* style={{ height: 980 }} */>

                            <div className='HomeFullSecond'  >
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                                    <h1 className='title '>
                                        BIENVENIDO AL DINÁMICO
                                        <br />
                                        VECINDARIO DE EL FRUTAL
                                    </h1>
                                </ScrollAnimation>
                                <div className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                                    <h1 className='title '>
                                        BIENVENIDO AL DINÁMICO
                                        <br />
                                        VECINDARIO DE EL FRUTAL
                                    </h1>
                                </div>
                                <ScrollAnimation style={{ textAlign: '-webkit-center' }} delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                                    <p className='text'>
                                        Rodeada de áreas comerciales, condominios residenciales y colegios, Alaia cuenta con una ubicación
                                        privilegiada a pocos metros del Complejo Comercial El Frutal
                                    </p>
                                </ScrollAnimation>

                                <div style={{ textAlign: '-webkit-center' }} className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                                    <p className='text'>
                                        Rodeada de áreas comerciales, condominios residenciales y colegios, Alaia cuenta con una ubicación
                                        privilegiada a pocos metros del Complejo Comercial El Frutal
                                    </p>
                                </div>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                                    <a href='/ubicacion' className='removeStyleHomeApto'>
                                        <Button className='buttonApto'>
                                            Conoce más
                                        </Button>
                                    </a>
                                </ScrollAnimation>
                            </div>
                            <div>
                                <img
                                    /* onClick={() => this.handleClick()} */
                                    className='imageArrowHsome' src={image1} alt="Flecha hacia abajo" />
                            </div>
                        </div>
                        {/*  </Parallax> */}
                    </div>

                    <div className='parallaxphone2 d-none d-lg-block'>
                         <Parallax bgImage={image2} strength={150}>
                        <div className='HomeFullSecondIMAGE' style={{ height: 1320 }}>

                            <div className='HomeFullThird'  >
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                                    <h1 className='title '>
                                        TU PROPIO ESPACIO,
                                        <br />
                                        TU NUEVO HOGAR
                                    </h1>
                                </ScrollAnimation>
                                <div className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                                    <h1 className='title '>
                                        TU PROPIO ESPACIO,
                                        <br />
                                        TU NUEVO HOGAR
                                    </h1>
                                </div>
                                <ScrollAnimation style={{ textAlign: '-webkit-center' }} delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                                    <p className='text'>
                                        Alaia integra la conveniencia y el confort convirtiéndose en un espacio único al cual llamar hogar.
                                    </p>
                                </ScrollAnimation>

                                <div style={{ textAlign: '-webkit-center' }} className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                                    <p className='text'>
                                        Alaia integra la conveniencia y el confort convirtiéndose en un espacio único al cual llamar hogar.
                                    </p>
                                </div>
                                <ScrollAnimation delay={100} animateIn="fadeIn">
                                    <a href={`${process.env.PUBLIC_URL}./ALAIA_Presentaciondigital.pdf`} download="ALAIA_Presentaciondigital.pdf" className='removeStylesHome' animateOnce={true}>
                                        <Button className='buttonUbi'>
                                            <p className='buttonText'>
                                                Presentación Digital
                                            </p>
                                        </Button>
                                    </a>
                                </ScrollAnimation>
                            </div>
                            
                        </div>
                         </Parallax>
                    </div>
                    <div className='parallaxphoneUbi d-block d-lg-none'>
                        {/* <Parallax bgImage={image2} strength={200} > */}
                            <div className='HomeFullSecondIMAGE' style={{ height: 980 }}>

                                <div className='HomeFullThird'  >
                                    <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                                        <h1 className='title '>
                                            TU PROPIO ESPACIO,
                                            <br />
                                            TU NUEVO HOGAR
                                        </h1>
                                    </ScrollAnimation>
                                    <div className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                                        <h1 className='title '>
                                            TU PROPIO ESPACIO,
                                            <br />
                                            TU NUEVO HOGAR
                                        </h1>
                                    </div>
                                    <ScrollAnimation style={{ textAlign: '-webkit-center' }} delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                                        <p className='text'>
                                            Alaia integra la conveniencia y el confort convirtiéndose en un espacio único al cual llamar hogar.
                                        </p>
                                    </ScrollAnimation>

                                    <div style={{ textAlign: '-webkit-center' }} className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                                        <p className='text'>
                                            Alaia integra la conveniencia y el confort convirtiéndose en un espacio único al cual llamar hogar.
                                        </p>
                                    </div>
                                    <ScrollAnimation delay={100} animateIn="fadeIn">
                                        <a href={`${process.env.PUBLIC_URL}./ALAIA_Presentaciondigital.pdf`} download="ALAIA_Presentaciondigital.pdf" className='removeStylesHome' animateOnce={true}>
                                            <Button className='buttonUbi'>
                                                <p className='buttonText'>
                                                    Presentación Digital

                                                </p>
                                            </Button>
                                        </a>
                                    </ScrollAnimation>
                                </div>
                                <div>
                                <img
                                    /* onClick={() => this.handleClick()} */
                                    className='imageArrowHsome' src={image2} alt="Flecha hacia abajo" />
                            </div>
                            </div>
                       {/*  </Parallax> */}
                    </div>

                    {/* <div className="fullscreen-videoHomeFull" >
                        <video autoPlay loop muted>
                            <source src={video} type="video/mp4" />
                            Tu navegador no admite la reproducción de video.
                        </video>
                    </div> */}
                </div>
                {/*  <div >
                    <div className='HomeFullSecond'>
                        <h1 className='title'>
                            HOLA CASAS EVERO
                        </h1>
                        <p className='text'>
                            Casas desde 1000m en San Miguel Petapa
                        </p>
                        <Button className='button'>
                            <p className='buttonText'>
                                Conoce más
                            </p>
                        </Button>
                    </div>
                    <div className="fullscreen-video">
                        <video autoPlay loop muted>
                            <source src={video} type="video/mp4" />
                            Tu navegador no admite la reproducción de video.
                        </video>
                    </div>
                </div>
                <div >
                    <div className='HomeFullThird'>
                        <h1 className='title'>
                            HOLA CASAS EVERO
                        </h1>
                        <p className='text'>
                            Casas desde 1000m en San Miguel Petapa
                        </p>
                        <Button className='button'>
                            <p className='buttonText'>
                                Conoce más
                            </p>
                        </Button>
                    </div>
                    <div className="fullscreen-video">
                        <video autoPlay loop muted>
                            <source src={video} type="video/mp4" />
                            Tu navegador no admite la reproducción de video.
                        </video>
                    </div>
                </div>  */}
            </div>
        );
    }
}

export default HomeFull;
