import React from 'react';
import { Navbar, Button } from 'reactstrap';
import './style.css'
import logo from '../../assets/logo.png'
import logoG from '../../assets/logoG.png'
import back from '../../assets/navbar/back.svg'

const pathname = window.location.pathname

class MyNavbar extends React.Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
      scrolled: true,
      waiting: false
    }
  }
  toggleMenu = () => {
    this.setState({
      menuOpen: !this.state.menuOpen
    });
    if (!this.state.menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

  };

  handleAnimation = () => {
    this.setState({ waiting: true });
    setTimeout(() => {
      this.setState({ waiting: false });
    }, 4500); // Espera medio segundo antes de regresar al estado original
  };

  componentDidMount() {
    this.handleAnimation()
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    // Obtén la posición actual del scroll en píxeles
    const scrollY = window.scrollY;

    // Define la posición de desplazamiento en la que deseas realizar una acción (en este caso, 100px)
    const scrollThreshold = 75;

    // Comprueba si el usuario ha desplazado más de 100px
    if (scrollY >= scrollThreshold) {
      this.setState({ scrolled: false });
    } else {
      this.setState({ scrolled: true });
    }
  };


  render() {
    const { scrolled } = this.state;
    return (
      <>{pathname === '/' ?
        <>
          {
            
            <div className={`${!scrolled ? 'shadowNav' : 'shadowNavOff'} custom-navbar ${this.state.menuOpen ? 'overlayMenu ' : ''} `}>
              <Navbar  >
                <div className="" onClick={() => this.toggleMenu()}>
                  {
                    pathname === '/contacto' ?
                      <div className='menuAnimation' style={{ cursor: 'pointer' }}>
                        <div className={`${scrolled ? ' barContact ' : 'bar'} `}></div>
                        <div className={`${scrolled ? "barSecondContact" : 'barSecond'} `}></div>
                        <div className={`${scrolled ? ' barContact ' : 'bar'} `}></div>
                      </div>
                      :
                      <div className={`menuAnimation  `} style={{ cursor: 'pointer' }}>
                        <div className="bar"></div>
                        <div className="barSecond"></div>
                        <div className="bar"></div>
                      </div>
                  }
                </div>
                {
                  pathname === '/contacto' ?
                    <>


                      <div className={` ${scrolled ? 'LogoContact' : 'ocultar'}`}>

                        <a href="/">
                          <img
                            className='imageLogo'
                            src={logoG} alt='evero' />
                        </a>
                      </div>




                    </>
                    :
                    <>
                      {
                        <div className={` ${scrolled ? 'Logo' : 'ocultar'}`}>

                          <a href="/">
                            <img
                              className='imageLogo'
                              src={logo} alt='evero' />
                          </a>
                        </div>

                      }
                    </>
                }
                <div className="contactoHidden">
                  {
                    pathname === '/contacto' ?
                      <a href="/" className='contactArrow'>
                        <img
                          className='imageLogoContact'
                          src={back} alt='everoBack' />
                      </a>
                      :
                      <a href="/contacto">
                        <Button className='contactoButton'>CONTACTO</Button>
                      </a>
                  }
                </div>
              </Navbar>
              <div onClick={() => this.toggleMenu()} className={`${this.state.menuOpen ? 'overlayMenu' : ''}`}>
                <div className={`menu ${this.state.menuOpen ? 'open' : ''}`}>
                  <button className="toggle-button" onClick={this.toggleMenu}>
                    <div className={`x  ${this.state.menuOpen ? 'activeNav' : 'deactive'}`}></div>
                  </button>
                  <ul className='removeML'>
                    <li className='Menu1'>
                      <a className='removeStyle' href='/'>

                        INICIO
                      </a>
                    </li >
                  {/*   <li className='Menu2'>
                      <a className='removeStyle' href='/house'>
                        CASAS
                      </a>
                    </li> */}
                    <li className='Menu3'>
                      <a className='removeStyle' href='/apartamentos'>
                        APARTAMENTOS
                      </a>
                    </li>
                    <li className='Menu4'>
                      <a className='removeStyle' href='/ubicacion'>
                        UBICACIÓN
                      </a>
                    </li>
                    <li className='Menu5'>
                      <a className='removeStyle' href='/contacto'>
                        CONTACTO
                      </a>
                    </li>
                    <li className='Menu6'>
                      <a className='removeStyle' href='https://rosul.com.gt/'>
                        ROSUL
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          }

        </>
        :
        <div className={`${!scrolled ? 'shadowNav' : 'shadowNavOff'} custom-navbar ${this.state.menuOpen ? 'overlayMenu ' : ''} `}>
          <Navbar  >
            <div className="" onClick={() => this.toggleMenu()}>
              {
                pathname === '/contacto' ?
                  <div className='menuAnimation' style={{ cursor: 'pointer' }}>
                    <div className={`${scrolled ? ' barContact ' : 'bar'} `}></div>
                    <div className={`${scrolled ? "barSecondContact" : 'barSecond'} `}></div>
                    <div className={`${scrolled ? ' barContact ' : 'bar'} `}></div>
                  </div>
                  :
                  <div className={`menuAnimation  `} style={{ cursor: 'pointer' }}>
                    <div className="bar"></div>
                    <div className="barSecond"></div>
                    <div className="bar"></div>
                  </div>
              }
            </div>
            {
              pathname === '/contacto' ?
                <>


                  <div className={` ${scrolled ? 'LogoContact' : 'ocultar'}`}>

                    <a href="/">
                      <img
                        className='imageLogo'
                        src={logoG} alt='evero' />
                    </a>
                  </div>




                </>
                :
                <>
                  {
                    <div className={` ${scrolled ? 'Logo' : 'ocultar'}`}>

                      <a href="/">
                        <img
                          className='imageLogo'
                          src={logo} alt='evero' />
                      </a>
                    </div>

                  }
                </>
            }
            <div className="contactoHidden">
              {
                pathname === '/contacto' ?
                  <a href="/" className='contactArrow'>
                    <img
                      className='imageLogoContact'
                      src={back} alt='everoBack' />
                  </a>
                  :
                  <a href="/contacto">
                    <Button className='contactoButton'>CONTACTO</Button>
                  </a>
              }
            </div>
          </Navbar>
          <div onClick={() => this.toggleMenu()} className={`${this.state.menuOpen ? 'overlayMenu' : ''}`}>
            <div className={`menu ${this.state.menuOpen ? 'open' : ''}`}>
              <button className="toggle-button" onClick={this.toggleMenu}>
                <div className={`x  ${this.state.menuOpen ? 'activeNav' : 'deactive'}`}></div>
              </button>
              <ul className='removeML'>
                <li className='Menu1'>
                  <a className='removeStyle' href='/'>

                  INICIO
                  </a>
                </li >
                {/* <li className='Menu2'>
                  <a className='removeStyle' href='/house'>
                    CASAS
                  </a>
                </li> */}
                <li className='Menu3'>
                  <a className='removeStyle' href='/apartamentos'>
                    APARTAMENTOS
                  </a>
                </li>
                <li className='Menu4'>
                  <a className='removeStyle' href='/ubicacion'>
                    UBICACIÓN
                  </a>
                </li>
                <li className='Menu5'>
                  <a className='removeStyle' href='/contacto'>
                    CONTACTO
                  </a>
                </li>
                <li className='Menu6'>
                  <a className='removeStyle' href='https://rosul.com.gt/'>
                    ROSUL
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      }

      </>
    );
  }
}

export default MyNavbar;
