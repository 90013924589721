import React from 'react';
import './style.css'
import { Row, Col } from 'reactstrap';
import logo from '../../assets/logo.png'
import logo360 from '../../assets/apartament/360.svg'
import amenidades from '../../assets/apartament/Amenidades.png'
import house1 from '../../assets/house/house1.png'
import eliaPortada from '../../assets/house/elia/portada.png'
import eliaPb from '../../assets/house/eliaPb.png'
import eliaN1 from '../../assets/house/elian1.png'
import eliaN2 from '../../assets/house/elian2.png'

import eliaPb3d from '../../assets/house/elia/3d/ELIA 3D PB.png'
import eliaN13d from '../../assets/house/elia/3d/ELIA 3D N1.png'
import eliaN23d from '../../assets/house/elia/3d/ELIA 3D N2.png'



import naturaPortada from '../../assets/house/natura/portada.png'
import natura1 from '../../assets/house/NATURA N1.png'
import natura2 from '../../assets/house/NATURA N2.png'
import natura3 from '../../assets/house/NATURA N3.png'

import natura13d from '../../assets/house/natura/3d/NATURA 3D PB.png'
import natura23d from '../../assets/house/natura/3d/NATURA 3D N1.png'
import natura33d from '../../assets/house/natura/3d/NATURA 3D N2.png'


import vestra1 from '../../assets/house/VESTRA N1.png'
import vestra2 from '../../assets/house/VESTRA N2.png'

import vestra13d from '../../assets/house/vestra/VESTRA N1.png'
import vestra23d from '../../assets/house/vestra/VESTRA N2.png'

import amenidad1 from '../../assets/house/amenidades/Salón de Usos Multiples 01.png'
import amenidad2 from '../../assets/house/amenidades/Casa Club 02.png'
import amenidad3 from '../../assets/house/amenidades/Casa Club 01.png'
import amenidad4 from '../../assets/house/amenidades/Piscina.png'
import amenidad5 from '../../assets/house/amenidades/Salón de Usos Multiples 02.png'

import casaclub2 from '../../assets/house/amenidades/casaclub2.png'
import ScrollAnimation from 'react-animate-on-scroll';
import ImageCarouselModal from '../ModalImages/index';
import $ from 'jquery';
import { Parallax, Background } from "react-parallax";

class HouseFull extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: true,
            selectedHouse: null,
            size: '',
            flagAmenidades: 1,
            amenidades: [
                amenidad1,
                amenidad2,
                amenidad3,
                amenidad4,
                amenidad5
            ],
            amenidadesName: [
                "SALÓN DE USOS",
                "CASA CLUB",
                "CASA CLUB",
                "PISCINA",
                "SALÓN DE USOS"
            ],
            plano3DElia: [
                eliaPb3d,
                eliaN13d,
                eliaN23d
            ],
            plano3DNatura: [
                natura13d,
                natura23d,
                natura33d
            ],
            plano3DVestra: [
                vestra13d,
                vestra23d,
            ],
            plano2DElia: [
                eliaPb,
                eliaN1,
                eliaN2
            ],
            plano2DNatura: [
                natura1,
                natura2,
                natura3
            ],
            plano2DVestra: [
                vestra1,
                vestra2,
            ],
        };

    }


    componentDidMount() {
        // My attempts to always have carousel begin at index 0 on show event
        $('#largeModal').on('show.bs.modal', function () {
            console.log('show.bs.modal event');
            $('#carousel').carousel(0);
        });
        $('#largeModal').on('show.bs.modal', function () {
            console.log('show.bs.modal event');
            $('#myCarousel').carousel(0);
        });
    }


    render() {

        const openModal = () => {
            this.setState({
                isModalOpen: true
            })
        };

        const closeModal = () => {
            this.setState({
                isModalOpen: false
            })
        };


        return (
            <div>

                <div
                    className='modal fade'
                    id='largeModal'
                    tabIndex='-1'
                    role='dialog'
                    aria-labelledby='basicModal'
                    aria-hidden='true'
                >
                    {
                        this.state.flagAmenidades === 1 ?
                            <div className='modal-dialog modal-lg modalSize'
                                style={{ paddingTop: this.state.size === 'big' ? '8vh' : '2vh' }}
                            >
                                <div className='modal-content' style={{ backgroundColor: 'transparent', border: 'none' }}>
                                    <div className='modal-body'>
                                        {/* Carousel */}
                                        <div
                                            id='myCarousel'
                                            className='carousel slide'
                                            data-ride='carousel'
                                        >
                                            {/* <ol className='carousel-indicators'>
                                                <li
                                                    data-target='#myCarousel'
                                                    data-slide-to='0'
                                                    className='active'
                                                ></li>
                                                <li data-target='#myCarousel' data-slide-to='1'></li>
                                                <li data-target='#myCarousel' data-slide-to='2'></li>
                                            </ol> */}
                                            <div className='carousel-inner' style={{ backgroundColor: this.state.flagAmenidades === 1 ? 'transparent !important' : 'white' }}>
                                                {this.state.selectedHouse && this.state.selectedHouse.map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className={
                                                            index === 0 ? 'carousel-item active' : 'carousel-item'
                                                        }
                                                    >
                                                        <>
                                                            <img
                                                                style={{ width: this.state.size === 'big' ? '90%' : '60%' }}
                                                                className={item.className}
                                                                src={item}
                                                                alt={item.alt}
                                                            />
                                                            {this.state.flagAmenidades === 1 &&
                                                                <h1 style={{ color: 'white', marginTop: '15px' }}>
                                                                    {this.state.amenidadesName[index]}
                                                                </h1>}

                                                        </>
                                                    </div>
                                                ))}
                                            </div>
                                            <a
                                                className='carousel-control-prev'
                                                href='#myCarousel'
                                                role='button'
                                                data-slide='prev'
                                                style={{ left: '-100px' }}
                                            >
                                                <span
                                                    className='carousel-control-prev-icon'
                                                    aria-hidden='true'
                                                >
                                                    {' '}
                                                </span>
                                                <span className='sr-only'>Previous</span>
                                            </a>
                                            <a
                                                className='carousel-control-next'
                                                href='#myCarousel'
                                                role='button'
                                                data-slide='next'
                                                style={{ right: '-100px' }}
                                            >
                                                <span
                                                    className='carousel-control-next-icon'
                                                    aria-hidden='true'
                                                ></span>
                                                <span className='sr-only'>Next</span>
                                            </a>
                                        </div>
                                    </div>
                                    {/*  <div className='modal-footer'>
                                        <button
                                            type='button'
                                            className='btn btn-default'
                                            data-dismiss='modal'
                                        >
                                            Close
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                            :
                            <div className='modal-dialog modal-lg'
                                style={{ paddingTop: this.state.size === 'big' ? '8vh' : '2vh' }}
                            >
                                <div className='modal-content'>
                                    <div className='modal-body'>
                                        {/* Carousel */}
                                        <div
                                            id='myCarousel'
                                            className='carousel slide'
                                            data-ride='carousel'
                                        >
                                            {/* <ol className='carousel-indicators'>
                                                <li
                                                    data-target='#myCarousel'
                                                    data-slide-to='0'
                                                    className='active'
                                                ></li>
                                                <li data-target='#myCarousel' data-slide-to='1'></li>
                                                <li data-target='#myCarousel' data-slide-to='2'></li>
                                            </ol> */}
                                            <div className='carousel-inner' style={{ backgroundColor: '#ffffff' }}>
                                                {this.state.selectedHouse && this.state.selectedHouse.map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className={
                                                            index === 0 ? 'carousel-item active' : 'carousel-item'
                                                        }
                                                    >
                                                        <img
                                                            style={{ width: this.state.size === 'big' ? '90%' : '60%' }}
                                                            className={item.className}
                                                            src={item}
                                                            alt={item.alt}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            <a
                                                className='carousel-control-prev'
                                                href='#myCarousel'
                                                role='button'
                                                data-slide='prev'
                                                style={{ left: '-100px' }}
                                            >
                                                <span
                                                    className='carousel-control-prev-icon'
                                                    aria-hidden='true'
                                                >
                                                    {' '}
                                                </span>
                                                <span className='sr-only'>Previous</span>
                                            </a>
                                            <a
                                                className='carousel-control-next'
                                                href='#myCarousel'
                                                role='button'
                                                data-slide='next'
                                                style={{ right: '-100px' }}
                                            >
                                                <span
                                                    className='carousel-control-next-icon'
                                                    aria-hidden='true'
                                                ></span>
                                                <span className='sr-only'>Next</span>
                                            </a>
                                        </div>
                                    </div>
                                    {/*  <div className='modal-footer'>
                                        <button
                                            type='button'
                                            className='btn btn-default'
                                            data-dismiss='modal'
                                        >
                                            Close
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                    }


                </div>
                <div >
                    <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                        <div className='HouseFull'>
                            <img className='logoCasas' src={logo} alt='logoEVERO' />
                            <h1 className='title'>
                                CASAS
                            </h1>
                        </div>
                    </ScrollAnimation>

                    <div delay={100} animateIn="fadeIn" animateOnce={true} className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                        <div className='HouseFull'>
                            <img className='logoCasas' src={logo} alt='logoEVERO' />
                            <h1 className='title'>
                                CASAS
                            </h1>
                        </div>
                    </div>
                    <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                        <div className='div1House'>
                            <div className='text1House'>
                                <p >
                                    El lado norte de Evero contará con un condominio residencial de clase mundial
                                    que sobresale por su arquitectura innovadora, excelente diseño y su modernidad.
                                    El proyecto contará con 314 viviendas y 3 diferentes modelos de 3 y 4 habitaciones.
                                    Adicional, el condominio contará con una combinación de amenidades que complementan y
                                    redefinen la experiencia de vida de sus residentes. En su propuesta comercial, Evero busca
                                    introducir a sus residentes a un nuevo estándar de vida sostenible e integrado a su entorno.
                                </p>
                            </div>
                            {/*  <div className='imageHouse1'>
                                <img className='image1' src={house1} alt='imageEvero' />
                            </div> */}
                        </div>
                    </ScrollAnimation>

                    <div delay={100} animateIn="fadeIn" animateOnce={true} className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                        <div className='div1House'>
                            <div className='text1House'>
                                <p >
                                    El lado norte de Evero contará con un condominio residencial de clase mundial
                                    que sobresale por su arquitectura innovadora, excelente diseño y su modernidad.
                                    El proyecto contará con 314 viviendas y 3 diferentes modelos de 3 y 4 habitaciones.
                                    Adicional, el condominio contará con una combinación de amenidades que complementan y
                                    redefinen la experiencia de vida de sus residentes. En su propuesta comercial, Evero busca
                                    introducir a sus residentes a un nuevo estándar de vida sostenible e integrado a su entorno.
                                </p>
                            </div>
                            {/*  <div className='imageHouse1'>
                                <img className='image1' src={house1} alt='imageEvero' />
                            </div> */}
                        </div>
                    </div>
                    <div className='HouseFullLast'>
                        <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                            <img className='logo360' src={logo360} alt='logoEVERO' />
                            <h1 className='title'>
                                <a className='removeStyle' href='https://www.arq-3d.com/EveroCasas/home.html'>
                                    Modelo 360°
                                </a>
                            </h1>
                        </ScrollAnimation>
                    </div>

                    {/* Modelo Vestra */}
                    <div>
                        {/* Nombre modelo */}
                        <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                            <div className='Modelo'>
                                <p className='TitleModelo'>
                                    Vestra
                                </p>
                                <div className='NumberModelo'>
                                    <p className='textNumberModelo'>
                                        169 m²
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                        {/* Fotos modelo */}
                        <div className='DivFotosComplete'>
                            <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                                <div className='FotosModeloDivImage'>
                                    <img className='fotoModelo' src={eliaPortada} alt='imageEVERO' />

                                </div>
                            </ScrollAnimation>
                            <div className='FotosModeloDiv'>
                                <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                                    <div className='d-block d-lg-none'>
                                        <div style={{ display: 'flex' }}>
                                            <p className='textModelo' style={{ marginTop: '0px' }}>
                                                - Sala Familiar
                                            </p>
                                            <p className='textModelo'>
                                                - 3 Habitaciones
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <p className='textModelo'>
                                                - Cocina
                                            </p>
                                            <p className='textModelo'>
                                                - Comedor
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <p className='textModelo'>
                                                - Sala
                                            </p>
                                            <p className='textModelo'>
                                                - 2 baños
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <p className='textModelo'>
                                                - Lavandería
                                            </p>
                                            <p className='textModelo'>
                                                - Baño de Visitas
                                            </p>
                                        </div>
                                        <p className='textModelo'>

                                        </p>
                                    </div>
                                    <div className='d-none d-lg-block'>
                                        <p className='textModelo' style={{ marginTop: '0px' }}>
                                            - Sala Familiar
                                        </p>
                                        <p className='textModelo'>
                                            - 3 Habitaciones
                                        </p>
                                        <p className='textModelo'>
                                            - Cocina
                                        </p>
                                        <p className='textModelo'>
                                            - Comedor
                                        </p>
                                        <p className='textModelo'>
                                            - Sala
                                        </p>
                                        <p className='textModelo'>
                                            - 2 baños
                                        </p>
                                        <p className='textModelo'>
                                            - Lavandería
                                        </p>
                                        <p className='textModelo'>
                                            - Baño de Visitas
                                        </p>
                                        <p className='textModelo'>

                                        </p>
                                    </div>
                                </ScrollAnimation>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <button type='button'
                                        onClick={() => this.setState({
                                            selectedHouse: this.state.plano2DVestra,
                                            size: 'small',
                                            flagAmenidades: 0
                                        })}
                                        style={{marginTop:'30px'}}
                                        data-toggle='modal'
                                        data-target='#largeModal' className='ButtonModeloStyle'>
                                        <p className='ButtonModeloText'>
                                            Plano 2D
                                        </p>
                                    </button>
                                </ScrollAnimation>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <button
                                        onClick={() => this.setState({
                                            selectedHouse: this.state.plano3DVestra,
                                            size: 'big',
                                            flagAmenidades: 0
                                        })}
                                        data-toggle='modal'
                                        data-target='#largeModal'
                                        className='ButtonModeloStyle2'>
                                        <p className='ButtonModeloText'>
                                            Plano 3D
                                        </p>
                                    </button>
                                </ScrollAnimation>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <button className='ButtonModeloStyle2'>
                                        <p className='ButtonModeloText'>
                                            <a className='removeStyle360' href='https://www.arq-3d.com/EveroCasas/CasaVestra.html'>
                                                Modelo 360°
                                            </a>
                                        </p>
                                    </button>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>


                    {/* Modelo Natura */}
                    <div>
                        {/* Nombre modelo */}
                        <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                            <div className='Modelo'>
                                <p className='TitleModelo'>
                                    Natura
                                </p>
                                <div className='NumberModelo'>
                                    <p className='textNumberModelo'>
                                        191 m²
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                        {/* Fotos modelo */}
                        <div className='DivFotosComplete'>
                            <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                                <div className='FotosModeloDivImage'>
                                    <img className='fotoModelo' src={naturaPortada} alt='imageEVERO' />

                                </div>
                            </ScrollAnimation>
                            <div className='FotosModeloDiv'>
                                <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                                    <div className='d-none d-lg-block' >
                                        <p className='textModelo' style={{ marginTop: '0px' }}>
                                            - 3 Habitaciones
                                        </p>
                                        <p className='textModelo'>
                                            - Sala Familiar
                                        </p>
                                        <p className='textModelo'>
                                            - Sala
                                        </p>
                                        <p className='textModelo'>
                                            - Cocina
                                        </p>
                                        <p className='textModelo'>
                                            - Comedor
                                        </p>
                                        <p className='textModelo'>
                                            - 2 Baños
                                        </p>
                                        <p className='textModelo'>
                                            - Estudio
                                        </p>
                                        <p className='textModelo'>
                                            - Lavandería
                                        </p>
                                        <p className='textModelo'>
                                            - Cuarto de Servicio
                                        </p>
                                        <p className='textModelo'>
                                            - Baño de Visitas
                                        </p>
                                    </div>
                                    <div className='d-block d-lg-none'>
                                        <div style={{ display: 'flex' }}>
                                            <p className='textModelo' style={{ marginTop: '0px' }}>
                                                - 3 Habitaciones
                                            </p>
                                            <p className='textModelo'>
                                                - Sala Familiar
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <p className='textModelo'>
                                                - Sala
                                            </p>
                                            <p className='textModelo'>
                                                - Cocina
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <p className='textModelo'>
                                                - Comedor
                                            </p>
                                            <p className='textModelo'>
                                                - 2 Baños
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <p className='textModelo'>
                                                - Estudio
                                            </p>
                                            <p className='textModelo'>
                                                - Lavandería
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <p className='textModelo'>
                                                - Cuarto de Servicio
                                            </p>
                                            <p className='textModelo'>
                                                - Baño de Visitas
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <button className='ButtonModeloStyleSecond'
                                        onClick={() => this.setState({
                                            selectedHouse: this.state.plano2DNatura,
                                            size: 'small',
                                            flagAmenidades: 0
                                        })}
                                        style={{marginTop:'30px'}}
                                        data-toggle='modal'
                                        data-target='#largeModal'
                                    >
                                        <p className='ButtonModeloText'>
                                            Plano 2D
                                        </p>
                                    </button>

                                </ScrollAnimation>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <button
                                        onClick={() => this.setState({
                                            selectedHouse: this.state.plano3DNatura,
                                            size: 'big',
                                            flagAmenidades: 0
                                        })}
                                        data-toggle='modal'
                                        data-target='#largeModal'
                                        className='ButtonModeloStyle2'>
                                        <p className='ButtonModeloText'>
                                            Plano 3D
                                        </p>
                                    </button>
                                </ScrollAnimation>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <button className='ButtonModeloStyle2'>
                                        <p className='ButtonModeloText'>
                                            <a className='removeStyle360' href='https://www.arq-3d.com/EveroCasas/CasaNatura.html'>
                                                Modelo 360°
                                            </a>
                                        </p>
                                    </button>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>


                    {/* Modelo Elia */}
                    <div>
                        {/* Nombre modelo */}
                        <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                            <div className='Modelo'>
                                <p className='TitleModelo'>
                                    Elia
                                </p>
                                <div className='NumberModelo'>
                                    <p className='textNumberModelo'>
                                        222 m²
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                        {/* Fotos modelo */}
                        <div className='DivFotosComplete'>
                            <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                                <div className='FotosModeloDivImage'>
                                    <img className='fotoModelo' src={house1} alt='imageEVERO' />

                                </div>
                            </ScrollAnimation>
                            <div className='FotosModeloDiv'>
                                <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                                    <div className='d-none d-lg-block'>
                                        <p className='textModelo' style={{ marginTop: '0px' }}>
                                            - 3 Habitaciones
                                        </p>
                                        <p className='textModelo'>
                                            - Comedor
                                        </p>
                                        <p className='textModelo'>
                                            - Sala
                                        </p>
                                        <p className='textModelo'>
                                            - Cocina
                                        </p>
                                        <p className='textModelo'>
                                            - Sala Familiar
                                        </p>
                                        <p className='textModelo'>
                                            - Estudio
                                        </p>
                                        <p className='textModelo'>
                                            - 3 Baños
                                        </p>
                                        <p className='textModelo'>
                                            - Lavandería
                                        </p>
                                        <p className='textModelo'>
                                            - Cuarto de Servicio
                                        </p>
                                        <p className='textModelo'>
                                            - Baño de Visitas
                                        </p>
                                    </div>
                                    <div className='d-block d-lg-none'>
                                        <div style={{ display: 'flex' }}>
                                            <p className='textModelo' style={{ marginTop: '0px' }}>
                                                - 3 Habitaciones
                                            </p>
                                            <p className='textModelo'>
                                                - Comedor
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                        <p className='textModelo'>
                                            - Sala
                                        </p>
                                        <p className='textModelo'>
                                            - Cocina
                                        </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                        <p className='textModelo'>
                                            - Sala Familiar
                                        </p>
                                        <p className='textModelo'>
                                            - Estudio
                                        </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                        <p className='textModelo'>
                                            - 3 Baños
                                        </p>
                                        <p className='textModelo'>
                                            - Lavandería
                                        </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                        <p className='textModelo'>
                                            - Cuarto de Servicio
                                        </p>
                                        <p className='textModelo'>
                                            - Baño de Visitas
                                        </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <button className='ButtonModeloStyleSecond'
                                        onClick={() => this.setState({
                                            selectedHouse: this.state.plano2DElia,
                                            size: 'small'
                                        })}
                                        style={{marginTop:'30px'}}
                                        data-toggle='modal'
                                        data-target='#largeModal'
                                    >
                                        <p className='ButtonModeloText'>
                                            Plano 2D
                                        </p>
                                    </button>
                                </ScrollAnimation>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <button className='ButtonModeloStyle2'
                                        onClick={() => this.setState({
                                            selectedHouse: this.state.plano3DElia,
                                            size: 'big',
                                            flagAmenidades: 0
                                        })}
                                        data-toggle='modal'
                                        data-target='#largeModal'
                                    >
                                        <p className='ButtonModeloText'>
                                            Plano 3D
                                        </p>
                                    </button>
                                </ScrollAnimation>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <button className='ButtonModeloStyle2'>
                                        <p className='ButtonModeloText'>
                                            <a className='removeStyle360' href='https://www.arq-3d.com/EveroCasas/CasaElia.html'>
                                                Modelo 360°
                                            </a>
                                        </p>
                                    </button>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>

                    <div className='HouseFullLast360'>
                        <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} >


                            <img className='logoamenidades' src={amenidades} alt='logoEVERO' />
                            <h1 className='titleA removeStyle'>
                                <a className='removeStyle' onClick={() => this.setState({
                                    selectedHouse: this.state.amenidades,
                                    size: 'big',
                                    flagAmenidades: 1
                                })}
                                    data-toggle='modal'
                                    data-target='#largeModal' >
                                    Amenidades
                                </a>
                            </h1>
                        </ScrollAnimation>
                    </div>
                    <div className='CasaClub d-block d-lg-none' style={{height: '300px'}}>
                        <Parallax style={{  width: '100% !important' }} bgImage={casaclub2} strength={50}>
                            <div style={{ height: 300 }}>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                                    {/* <img className='imageCasaClub' src={casaclub2} alt='logoEVERO' /> */}

                                </ScrollAnimation>
                            </div>
                        </Parallax>
                    </div>
                    <div className='CasaClub d-none d-lg-block'>
                        <Parallax style={{ height: '950px !important', width: '100% !important' }} bgImage={casaclub2} strength={180}>
                            <div style={{ height: 900 }}>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                                    {/* <img className='imageCasaClub' src={casaclub2} alt='logoEVERO' /> */}

                                </ScrollAnimation>
                            </div>
                        </Parallax>
                    </div>

                    {/*     <div className='divAmenidades'>
                        <div className='amenidades'>
                            <p className='textAmenidades'>
                                A- Jardin Zen
                            </p>
                            <p className='textAmenidades'>
                                B- Canchas Polideportivas
                            </p>
                            <p className='textAmenidades'>
                                C- Juegos Infantiles
                            </p>
                            <p className='textAmenidades'>
                                D- Gimnasio al Aire Libre
                            </p>
                        </div>
                        <div className='amenidades'>
                            <p className='textAmenidades'>
                                E- Área para Mascotas
                            </p>
                            <p className='textAmenidades'>
                                F- Casa Club
                            </p>
                            <p className='textAmenidades'>
                                G- 2 Salones Sociales
                            </p>
                            <p className='textAmenidades'>
                                H- Piscina
                            </p>
                        </div>
                    </div> */}
                </div>

            </div>
        );
    }
}

export default HouseFull;
