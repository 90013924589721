import React from 'react';
import './style.css'
import video from '../../assets/apartament/video.mp4'
import flecha from '../../assets/home/flecha.svg'


class Home extends React.Component {

  handleClick = () => {
    // Cuando se hace clic en la flecha, desplaza la pantalla hacia abajo 400px
    window.scrollTo({
      top: window.scrollY + 975,
      behavior: 'smooth', // Para un desplazamiento suave
    });
  };
  
  render() {
    return (
      <div>
      <div className="apartamentfullscreen-video">
      <video autoPlay loop muted playsInline>
          <source src={video} type="video/mp4" />
          Tu navegador no admite la reproducción de video.
        </video>
      </div>
        <div className='arrow-downHome'>
          <img 
          className='imageArrowHome'
          onClick={() => this.handleClick()}
          src={flecha} alt="Flecha hacia abajo" />
        </div>

    </div>
    );
  }
}

export default Home;
