import React from 'react';
import './style.css'
import { Row, Col } from 'reactstrap';
import amenidades from '../../assets/apartament/Amenidades.png'
import Logo360 from '../../assets/apartament/360.svg'
import house1 from '../../assets/apartament/fachada.png'
import logo from '../../assets/logo.png'
import aria from '../../assets/apartament/aria/portada.png'
import mara from '../../assets/apartament/mara/portada.png'
import zatti from '../../assets/apartament/zatti/zatti.png'
import nero from '../../assets/apartament/nero/portada.png'

import ariaImage from '../../assets/apartament/aria/Aria.png'
import maraImage from '../../assets/apartament/mara/Mara.png'
import zattiImage from '../../assets/apartament/zatti/Zatti2.png'
import neroImage from '../../assets/apartament/nero/NERO.png'

import aria3dImage from '../../assets/apartament/3D/ARIA 3D.png'
import mara3dImage from '../../assets/apartament/3D/MARA 3D.png'
import zatti3dImage from '../../assets/apartament/3D/ZATTI 3D.png'
import nero3dImage from '../../assets/apartament/3D/NERO 3D.png'

import amenidad1 from '../../assets/apartament/amenidades/A1_SUM.png'
import amenidad2 from '../../assets/apartament/amenidades/A2_SUM.png'
import amenidad3 from '../../assets/apartament/amenidades/a3.png'
import amenidad4 from '../../assets/apartament/amenidades/a4.png'
import amenidad5 from '../../assets/apartament/amenidades/a5.png'
import amenidad6 from '../../assets/apartament/amenidades/a6.png'


import ScrollAnimation from 'react-animate-on-scroll';
import casaclub2 from '../../assets/house/amenidades/casaclub2.png'
import $ from 'jquery';
import { Parallax, Background } from "react-parallax";


class HouseFull extends React.Component {
    constructor() {
        super();
        this.state = {
            modalOpen: false,
            selectedHouse: null,
            flagAmenidades: 1,
            amenidades: [
                amenidad1,
                amenidad2,
                amenidad3,
                amenidad4,
                amenidad5,
                amenidad6
            ],
            amenidadesName: [
                "SUM",
                "SUM",
                "PERGOLA",
                "CANCHA DEPORTIVA",
                "PARQUE",
                "PARQUE"
            ],
            size: '',
            plano3DAria: [
                aria3dImage,
            ],
            plano3DMara: [
                mara3dImage,
            ],
            plano3DZatti: [
                zatti3dImage,
            ],
            plano3DNero: [
                nero3dImage,
            ],
            plano2DAria: [
                ariaImage,
            ],
            plano2DMara: [
                maraImage,
            ],
            plano2DZatti: [
                zattiImage,
            ],
            plano2DNero: [
                neroImage,
            ],
        };
    }
    componentDidMount() {
        // My attempts to always have carousel begin at index 0 on show event
        $('#largeModal').on('show.bs.modal', function () {
            console.log('show.bs.modal event');
            $('#carousel').carousel(0);
        });
        $('#largeModal').on('show.bs.modal', function () {
            console.log('show.bs.modal event');
            $('#myCarousel').carousel(0);
        });
    }

    openModal = () => {
        this.setState({ modalOpen: true });
    };

    closeModal = () => {
        this.setState({ modalOpen: false });
    };

    render() {
        return (
            <div>

                <div
                    className='modal fade'
                    id='largeModal'
                    tabIndex='-1'
                    role='dialog'
                    aria-labelledby='basicModal'
                    aria-hidden='true'
                >

                    <button
                        type='button'
                        className='closes'
                        aria-label='Close'
                        style={{
                            position: 'absolute', top: '165px', left: '15px',
                            padding: '0',
                            backgroundColor: 'transparent',
                            border: '0',
                            WebkitAppearance: 'none',
                            zIndex: '999', fontSize: '4rem', color: '#ffffff',
                            opacity: '1 !important', fontWeight: '0 !important'
                        }}
                        data-toggle='modal'
                        data-target='#largeModal'
                        onClick={() => {

                            // Lógica para cerrar el modal
                        }}
                    >
                        <span style={{ opacity: '1 !important', fontWeight: '0 !important' }} aria-hidden='true'>&times;</span>
                    </button>
                    {
                        this.state.flagAmenidades === 1 ?
                            <div className='modal-dialog modal-lg modalSize'>
                                <div className={'modal-content modalmobile '} style={{ backgroundColor: 'transparent', border: 'none', borderColor: 'transparent' }}>
                                    <div className='modal-body'>
                                        {/* Carousel */}
                                        <div
                                            id='myCarousel'
                                            className='carousel slide'
                                            data-ride='carousel'

                                        >
                                            {/* <ol className='carousel-indicators'>
                                                <li
                                                    data-target='#myCarousel'
                                                    data-slide-to='0'
                                                    className='active'
                                                ></li>
                                                <li data-target='#myCarousel' data-slide-to='1'></li>
                                                <li data-target='#myCarousel' data-slide-to='2'></li>
                                            </ol> */}
                                            <div className='carousel-inner' style={{ backgroundColor: this.state.flagAmenidades === 1 ? 'transparent !important' : 'transparent', borderColor: 'transparent !important' }}>
                                                {this.state.selectedHouse && this.state.selectedHouse.map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className={
                                                            index === 0 ? 'carousel-item active' : 'carousel-item'
                                                        }
                                                    >
                                                        {
                                                            this.state.flagAmenidades === 1 ?
                                                                <div>
                                                                    <img
                                                                        style={{ width: '100%' }}
                                                                        className={item.className}
                                                                        src={item}
                                                                        alt={item.alt}
                                                                    />
                                                                    <h1 style={{ color: 'white', backgroundColor: 'transparent', marginTop: '10px' }}>
                                                                        {this.state.amenidadesName[index]}
                                                                    </h1>
                                                                </div>
                                                                :
                                                                <img
                                                                    style={{ width: this.state.size === 'big' ? '100%' : '100%' }}
                                                                    className={item.className}
                                                                    src={item}
                                                                    alt={item.alt}
                                                                />
                                                        }

                                                    </div>
                                                ))}
                                            </div>
                                            {this.state.flagAmenidades === 1 && <>
                                                <a
                                                    className='carousel-control-prev'
                                                    href='#myCarousel'
                                                    role='button'
                                                    data-slide='prev'
                                                    style={{ left: '-100px' }}
                                                >
                                                    <span
                                                        className='carousel-control-prev-icon'
                                                        aria-hidden='true'
                                                    >
                                                        {' '}
                                                    </span>
                                                    <span className='sr-only'>Previous</span>
                                                </a>
                                                <a
                                                    className='carousel-control-next'
                                                    href='#myCarousel'
                                                    role='button'
                                                    data-slide='next'
                                                    style={{ right: '-100px' }}
                                                >
                                                    <span
                                                        className='carousel-control-next-icon'
                                                        aria-hidden='true'
                                                    ></span>
                                                    <span className='sr-only'>Next</span>
                                                </a>
                                            </>}
                                        </div>
                                    </div>
                                    {/*  <div className='modal-footer'>
                                        <button
                                            type='button'
                                            className='btn btn-default'
                                            data-dismiss='modal'
                                        >
                                            Close
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                            :
                            <div className='modal-dialog modal-lg modalSize'>

                                <div className={'modal-content modalmobile'} style={{ borderColor: 'transparent', backgroundColor: this.state.flagAmenidades === 1 ? 'transparent !important' : 'transparent !important', borderColor: 'transparent !important' }}>
                                    <div className='modal-body'>

                                        {/* Carousel */}
                                        <div
                                            id='myCarousel'
                                            className='carousel slide'
                                            data-ride='carousel'
                                        >
                                            {/* <ol className='carousel-indicators'>
                                                <li
                                                    data-target='#myCarousel'
                                                    data-slide-to='0'
                                                    className='active'
                                                ></li>
                                                <li data-target='#myCarousel' data-slide-to='1'></li>
                                                <li data-target='#myCarousel' data-slide-to='2'></li>
                                            </ol> */}
                                            <div className='carousel-inner' style={{ backgroundColor: this.state.flagAmenidades === 1 ? 'transparent !important' : 'transparent', borderColor: 'transparent !important' }}>
                                                {this.state.selectedHouse && this.state.selectedHouse.map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className={
                                                            index === 0 ? 'carousel-item active' : 'carousel-item'
                                                        }
                                                    >
                                                        {
                                                            this.state.flagAmenidades === 1 ?
                                                                <img
                                                                    style={{ width: '120%' }}
                                                                    className={item.className}
                                                                    src={item}
                                                                    alt={item.alt}
                                                                />
                                                                :
                                                                <img
                                                                    style={{ width: this.state.size === 'big' ? '100%' : '60%' }}
                                                                    className={item.className}
                                                                    src={item}
                                                                    alt={item.alt}
                                                                />
                                                        }

                                                    </div>
                                                ))}
                                            </div>
                                            {/* <a
                                                className='carousel-control-prev'
                                                href='#myCarousel'
                                                role='button'
                                                data-slide='prev'
                                                style={{ left: '-100px' }}
                                            >
                                                <span
                                                    className='carousel-control-prev-icon'
                                                    aria-hidden='true'
                                                >
                                                    {' '}
                                                </span>
                                                <span className='sr-only'>Previous</span>
                                            </a>
                                            <a
                                                className='carousel-control-next'
                                                href='#myCarousel'
                                                role='button'
                                                data-slide='next'
                                                style={{ right: '-100px' }}
                                            >
                                                <span
                                                    className='carousel-control-next-icon'
                                                    aria-hidden='true'
                                                ></span>
                                                <span className='sr-only'>Next</span>
                                            </a> */}
                                        </div>
                                    </div>
                                    {/*  <div className='modal-footer'>
                                        <button
                                            type='button'
                                            className='btn btn-default'
                                            data-dismiss='modal'
                                        >
                                            Close
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                    }


                </div>


                <div >
                    <ScrollAnimation animateIn="fadeIn" className='d-none d-lg-block'>
                        <div className='apartamentHouseFull'>
                            {/* <img className='logoAmenidadesApto' src={logo} alt='logoEVERO' /> */}
                            <h1 className='apartamenttitle'>
                                APARTAMENTOS
                            </h1>
                        </div>
                    </ScrollAnimation>
                    <div animateIn="fadeIn" className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                        <div className='apartamentHouseFull'>
                            {/* <img className='logoAmenidadesApto' src={logo} alt='logoEVERO' /> */}
                            <h1 className='apartamenttitle'>
                                APARTAMENTOS
                            </h1>
                        </div>
                    </div>
                    <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='d-none d-lg-block'>
                        <div className='apartamentdiv1House'>
                            <div className='apartamenttext1House'>
                                <p >
                                    Alaia es la más reciente incorporación al dinámico vecindario de El Frutal en Villa Nueva.
                                    Rodeada de áreas comerciales, condominios residenciales y colegios, el condominio cuenta con una
                                    ubicación privilegiada a pocos metros del Complejo Comercial el Frutal. En Alaia podrás vivir un
                                    estilo de vida extraordinario en donde cada detalle fue pensado para disfrutar momentos de conexión
                                    familiar. Con tres tipologías de apartamentos que se adecuan a diferentes necesidades y una variedad
                                    de amenidades que incluyen áreas deportivas, juegos infantiles y un gimnasio al aire libre, Alaia
                                    integra la conveniencia y el confort convirtiéndose en un espacio único al cual llamar hogar.
                                </p>
                            </div>
                            {/*  <div className='apartamentimageHouse1'>
                                <img className='apartamentimage1' src={house1} alt='imageEvero' />
                            </div> */}
                        </div>
                    </ScrollAnimation>

                    <div delay={100} animateIn="fadeIn" animateOnce={true} className='d-block d-lg-none animate__animated animate__fadeIn animate__delay-0.5s'>
                        <div className='apartamentdiv1House'>
                            <div className='apartamenttext1House'>
                                <p >
                                    Alaia es la más reciente incorporación al dinámico vecindario de El Frutal en Villa Nueva. Rodeada de áreas comerciales,
                                    condominios residenciales y colegios, el condominio cuenta con una ubicación privilegiada a pocos metros del Complejo Comercial el Frutal.
                                    En Alaia podrás vivir un estilo de vida extraordinario con tres tipologías de apartamentos que se adecuan a diferentes necesidades y una
                                    variedad de amenidades que incluyen áreas deportivas, juegos infantiles y un gimnasio al aire libre.
                                </p>
                            </div>
                            {/*  <div className='apartamentimageHouse1'>
                                <img className='apartamentimage1' src={house1} alt='imageEvero' />
                            </div> */}
                        </div>
                    </div>
                    <div className='apartamentHouseFullLast'>
                        <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} >
                            <a className='removeStyle' href='https://www.arq-3d.com/Alaia/home.html'>
                                <img src={Logo360} className='logoAmenidadesApto' alt='logoEVERO' />
                                <h1 className='apartamenttitle'>
                                    Modelo 360°
                                </h1>
                            </a>
                        </ScrollAnimation>
                    </div>
                    {/* Modelo Zatti */}
                    <div>
                        {/* Nombre modelo */}
                        <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                            <div className='apartamentModelo'>
                                <p className='apartamentTitleModelo'>
                                    Cardiel
                                </p>
                                <div className='apartamentNumberModelo'>
                                    <p className='apartamenttextNumberModelo'>
                                        58 m²
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                        {/* Fotos modelo */}
                        <div className='DivFotosComplete'>
                            <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                                <div className='FotosModeloDivImage'>
                                    <img className='fotoModelo' src={zatti} alt='imageEVERO' />

                                </div>
                            </ScrollAnimation>
                            <div className='FotosModeloDiv'>
                                <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                                    <div className='d-none d-lg-block'>
                                        <p className='apartamenttextModelo' style={{ marginTop: '0px' }}>
                                            - Sala / Comedor
                                        </p>
                                        <p className='apartamenttextModelo'>
                                            - Cocina
                                        </p>
                                        <p className='apartamenttextModelo'>
                                            - Lavandería
                                        </p>
                                        <p className='apartamenttextModelo'>
                                            - 2 Habitaciones
                                        </p>
                                        <p className='apartamenttextModelo space'>
                                            - 2 Baños
                                        </p>
                                    </div>
                                    <div className='d-block d-lg-none'>
                                        <div style={{ display: 'flex', marginTop: '30px' }}>
                                            <p className='apartamenttextModelo' style={{ marginTop: '0px' }}>
                                                - Sala / Comedor
                                            </p>
                                            <p className='apartamenttextModelo'>
                                                - Cocina
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <p className='apartamenttextModelo'>
                                                - Lavandería
                                            </p>
                                            <p className='apartamenttextModelo'>
                                                - 2 Habitaciones
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', }}>
                                            <p className='apartamenttextModelo'>
                                                - 2 Baños
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                                {/*                                 <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <button
                                        onClick={() => this.setState({
                                            selectedHouse: this.state.plano2DZatti,
                                            size: 'small',
                                            flagAmenidades: 0
                                        })}
                                        data-toggle='modal'
                                        data-target='#largeModal'
                                        className='apartamentButtonModeloStyle'>
                                        <p className='apartamentButtonModeloText'>
                                            Plano 2D
                                        </p>
                                    </button>
                                </ScrollAnimation> */}
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <button
                                        onClick={() => this.setState({
                                            selectedHouse: this.state.plano3DZatti,
                                            size: 'big',
                                            flagAmenidades: 0
                                        })}
                                        data-toggle='modal'
                                        data-target='#largeModal'
                                        className='apartamentButtonModeloStyle2'>
                                        <p className='apartamentButtonModeloText'>
                                            Plano 3D
                                        </p>
                                    </button>
                                </ScrollAnimation>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <a className='removeStyle360' href='https://www.arq-3d.com/Alaia/ModeloCardiel.html'>
                                        <button className='apartamentButtonModeloStyle2'>
                                            <p className='apartamentButtonModeloText'>
                                                Modelo 360°
                                            </p>
                                        </button>
                                    </a>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>

                    {/* Modelo Mara */}
                    <div>
                        {/* Nombre modelo */}
                        <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                            <div className='apartamentModelo'>
                                <p className='apartamentTitleModelo'>
                                    Baikal
                                </p>
                                <div className='apartamentNumberModelo'>
                                    <p className='apartamenttextNumberModelo'>
                                        69 m²
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                        {/* Fotos modelo */}
                        <div className='DivFotosComplete'>
                            <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                                <div className='FotosModeloDivImage'>
                                    <img className='fotoModelo' src={mara} alt='imageEVERO' />

                                </div>
                            </ScrollAnimation>
                            <div className='FotosModeloDiv'>
                                <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                                    <div className='d-none d-lg-block'>
                                        <p className='apartamenttextModelo' style={{ marginTop: '0px' }}>
                                            - Sala / Comedor
                                        </p>
                                        <p className='apartamenttextModelo'>
                                            - Cocina
                                        </p>
                                        <p className='apartamenttextModelo'>
                                            - Lavandería
                                        </p>
                                        <p className='apartamenttextModelo'>
                                            - 3 Habitaciones
                                        </p>
                                        <p className='apartamenttextModelo space'>
                                            - 2 Baños
                                        </p>
                                    </div>
                                    <div className='d-block d-lg-none'>
                                        <div style={{ display: 'flex', marginTop: '30px' }}>
                                            <p className='apartamenttextModelo' style={{ marginTop: '0px' }}>
                                                - Sala / Comedor
                                            </p>
                                            <p className='apartamenttextModelo'>
                                                - Cocina
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <p className='apartamenttextModelo'>
                                                - Lavandería
                                            </p>
                                            <p className='apartamenttextModelo'>
                                                - 3 Habitaciones
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', }}>
                                            <p className='apartamenttextModelo'>
                                                - 2 Baños
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                                {/*                                 <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <button
                                        onClick={() => this.setState({
                                            selectedHouse: this.state.plano2DMara,
                                            size: 'small',
                                            flagAmenidades: 0
                                        })}
                                        data-toggle='modal'
                                        data-target='#largeModal'
                                        className='apartamentButtonModeloStyle'>
                                        <p className='apartamentButtonModeloText'>
                                            Plano 2D
                                        </p>
                                    </button>
                                </ScrollAnimation> */}
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <button
                                        onClick={() => this.setState({
                                            selectedHouse: this.state.plano3DMara,
                                            size: 'big',
                                            flagAmenidades: 0
                                        })}
                                        data-toggle='modal'
                                        data-target='#largeModal'
                                        className='apartamentButtonModeloStyle2'>
                                        <p className='apartamentButtonModeloText'>
                                            Plano 3D
                                        </p>
                                    </button>
                                </ScrollAnimation>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <a className='removeStyle360' href='https://www.arq-3d.com/Alaia/ModeloBaikal.html'>
                                        <button className='apartamentButtonModeloStyle2'>
                                            <p className='apartamentButtonModeloText'>
                                                Modelo 360°
                                            </p>
                                        </button>
                                    </a>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>

                    {/* Modelo Aria */}
                    <div>
                        {/* Nombre modelo */}
                        <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                            <div className='apartamentModelo'>
                                <p className='apartamentTitleModelo'>
                                    Aral
                                </p>
                                <div className='apartamentNumberModelo'>
                                    <p className='apartamenttextNumberModelo'>
                                        76 m²
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                        {/* Fotos modelo */}
                        <div className='DivFotosComplete'>
                            <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                                <div className='FotosModeloDivImage'>
                                    <img className='fotoModelo' src={aria} alt='imageEVERO' />

                                </div>
                            </ScrollAnimation>
                            <div className='FotosModeloDiv'>
                                <ScrollAnimation delay={100} animateIn="fadeInUp" animateOnce={true}>
                                    <div className='d-none d-lg-block'>
                                        <p className='apartamenttextModelo' style={{ marginTop: '0px' }}>
                                            - Sala / Comedor
                                        </p>
                                        <p className='apartamenttextModelo'>
                                            - Cocina
                                        </p>
                                        <p className='apartamenttextModelo'>
                                            - Lavandería
                                        </p>
                                        <p className='apartamenttextModelo'>
                                            - 3 Habitaciones
                                        </p>
                                        <p className='apartamenttextModelo space'>
                                            - 2 Baños
                                        </p>
                                    </div>
                                    <div className='d-block d-lg-none'>
                                        <div style={{ display: 'flex', marginTop: '30px' }}>
                                            <p className='apartamenttextModelo' style={{ marginTop: '0px' }}>
                                                - Sala / Comedor
                                            </p>
                                            <p className='apartamenttextModelo'>
                                                - Cocina
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <p className='apartamenttextModelo'>
                                                - Lavandería
                                            </p>
                                            <p className='apartamenttextModelo'>
                                                - 3 Habitaciones
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', }}>
                                            <p className='apartamenttextModelo'>
                                                - 2 Baños
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                                {/*                                 <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <button
                                        onClick={() => this.setState({
                                            selectedHouse: this.state.plano2DAria,
                                            size: 'small',
                                            flagAmenidades: 0
                                        })}
                                        data-toggle='modal'
                                        data-target='#largeModal'
                                        className='apartamentButtonModeloStyle'>
                                        <p className='apartamentButtonModeloText'>
                                            Plano 2D
                                        </p>
                                    </button>
                                </ScrollAnimation> */}
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <button
                                        onClick={() => this.setState({
                                            selectedHouse: this.state.plano3DAria,
                                            size: 'big',
                                            flagAmenidades: 0
                                        })}
                                        data-toggle='modal'
                                        data-target='#largeModal'
                                        className='apartamentButtonModeloStyle2'>
                                        <p className='apartamentButtonModeloText'>
                                            Plano 3D
                                        </p>
                                    </button>
                                </ScrollAnimation>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true} className='mobileButton'>
                                    <a className='removeStyle360' href='https://www.arq-3d.com/Alaia/ModeloAral.html'>
                                        <button className='apartamentButtonModeloStyle2'>
                                            <p className='apartamentButtonModeloText'>
                                                Modelo 360°
                                            </p>
                                        </button>
                                    </a>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>




                    <div className='apartamentHouseFullLast360'>
                        <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                            <img src={amenidades} alt='logoEVERO' className='logoAmenidadesApto' />
                            <h1 className='apartamenttitle removeStyle'
                                onClick={() => this.setState({
                                    flagAmenidades: 1
                                })}
                            >
                                <a className='removeStyleAmenidades'
                                    onClick={() => this.setState({
                                        selectedHouse: this.state.amenidades,
                                        size: 'big',
                                        flagAmenidades: 1

                                    })}
                                    data-toggle='modal'
                                    data-target='#largeModal'
                                >
                                    Amenidades
                                </a>
                            </h1>
                        </ScrollAnimation>
                    </div>

                    <div className='CasaClub d-block d-lg-none' style={{ height: '300px' }}>
                        <Parallax style={{ width: '100% !important' }} bgImage={casaclub2} strength={50}>
                            <div style={{ height: 300 }}>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                                    {/* <img className='imageCasaClub' src={casaclub2} alt='logoEVERO' /> */}

                                </ScrollAnimation>
                            </div>
                        </Parallax>
                    </div>
                    <div className='CasaClub d-none d-lg-block'>
                        <Parallax style={{ height: '950px !important', width: '100% !important' }} bgImage={casaclub2} strength={180}>
                            <div style={{ height: 900 }}>
                                <ScrollAnimation delay={100} animateIn="fadeIn" animateOnce={true}>
                                    {/* <img className='imageCasaClub' src={casaclub2} alt='logoEVERO' /> */}

                                </ScrollAnimation>
                            </div>
                        </Parallax>
                    </div>

                    {/* <div className='divAmenidades'>
                        <div className='amenidades'>
                            <p className='textAmenidades'>
                                A- Jardin Zen
                            </p>
                            <p className='textAmenidades'>
                                B- Canchas Polideportivas
                            </p>
                            <p className='textAmenidades'>
                                C- Juegos Infantiles
                            </p>
                            <p className='textAmenidades'>
                                D- Gimnasio al Aire Libre
                            </p>
                        </div>
                        <div className='amenidades'>
                            <p className='textAmenidades'>
                                E- Área para Mascotas
                            </p>
                            <p className='textAmenidades'>
                                F- Casa Club
                            </p>
                            <p className='textAmenidades'>
                                G- 2 Salones Sociales
                            </p>
                            <p className='textAmenidades'>
                                H- Piscina
                            </p>
                        </div>
                    </div> */}
                </div>

            </div >
        );
    }
}

export default HouseFull;
