import React from 'react';
import './style.css'
import portada from '../../assets/map/portada.png'
import video from '../../assets/map/video.mp4'
import flecha from '../../assets/home/flecha.svg'


class Home extends React.Component {

  handleClick = () => {
    // Cuando se hace clic en la flecha, desplaza la pantalla hacia abajo 400px
    window.scrollTo({
      top: window.scrollY + 975,
      behavior: 'smooth', // Para un desplazamiento suave
    });
  };

  render() {
    return (
      <div>
        <div className="Mapfullscreen-video">
          {/* <video autoPlay loop muted playsInline>
          <source src={video} type="video/mp4" />
          Tu navegador no admite la reproducción de video.
        </video> */}
          <img src={portada} alt='everoMap' />
        </div>
        <div className='arrow-downHome'>
          <img
            onClick={() => this.handleClick()}
            className='imageArrowHome' src={flecha} alt="Flecha hacia abajo" />
        </div>

      </div>
    );
  }
}

export default Home;
