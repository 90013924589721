import React from 'react';
import './style.css'
import video from '../../assets/home/video.mp4'
import introVideo  from '../../assets/intro/intro.mp4'
import flecha from '../../assets/home/flecha.svg'
import evero from '../../assets/logo.png'


class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      waiting: true,
      videoShow: introVideo 
    };
  }


  handleClick = () => {
    // Cuando se hace clic en la flecha, desplaza la pantalla hacia abajo 400px
    window.scrollTo({
      top: window.scrollY + 975,
      behavior: 'smooth', // Para un desplazamiento suave
    });
  };

  handleAnimation = () => {
    console.log("Inicio de handleAnimation");
    setTimeout(() => {
      console.log("Cambio de estado");
      this.setState({ waiting: false, videoShow: video }, () => {
        this.forceUpdate();
      });
    }, 4500);
  };

  componentDidMount() {
    this.handleAnimation()
  }
  render() {
    return (
      <div>
        <div className="fullscreen-video" onClick={this.handleAnimation}>

                <>
                <video autoPlay loop muted playsInline>
                  <source src={video} type="video/mp4" />
                  Tu navegador no admite la reproducción de video.
                </video>
                {/* <div className="overlay"></div> */}
              <div className='arrow-downHome'>
                <img className='imageArrowHome'  src={flecha} alt="Flecha hacia abajo" />
              </div>
            </>
        
        </div>


      </div>
    );
  }
}

export default Home;
