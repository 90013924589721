import React, { useState, useEffect } from 'react';

import {
  withRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//components
import Navbar from './components/navbar'
import Home from './components/Home/index'
import HomeFull from "./components/HomeFull/index";
import Footer from "./components/Footer/index";
import Contact from "./components/Contact/index";
import House from "./components/house/index";
import HouseFull from "./components/HouseFull/index";
import Map from "./components/Map/index";
import MapFull from "./components/MapFull/index";
import ApartamentFull from "./components/Apartaments";
import Apartament from './components/Apartament'

import introVideo from '../src/assets/intro/intro.mp4'
import introVideoResponsive from '../src/assets/intro/responsive.mp4'

import "./App.css"


const HOME = () => {
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    const handleAnimation = () => {
      console.log("Inicio de handleAnimation");
      setTimeout(() => {
        console.log("Cambio de estado");
        setWaiting(true);
      }, 5200);
    };

    handleAnimation(); // Llamada inicial al montar el componente

    // Limpieza del temporizador al desmontar el componente
    return () => clearTimeout(handleAnimation);
  }, []); // El segundo argumento del useEffect [] asegura que solo se ejecute una vez al montar el componente

  return (
    <>
      <div 
      //style={{ display: `${waiting ? 'block' : 'none'}` }}
      >
        <Navbar />
        <Home />
        <HomeFull />
        <Footer />
      </div>

{/*       <div style={{ display: `${waiting ? 'none' : 'block'}` }} className="fullscreen-video-intro showdivFade" onClick={() => setWaiting(true)}>
        <div className='d-none d-lg-block'>
          <video autoPlay loop muted playsInline>
            <source src={introVideo} type="video/mp4" />
            Tu navegador no admite la reproducción de video.
          </video>
        </div>
        <div className='d-block d-lg-none'>
          <video autoPlay loop muted playsInline>
            <source src={introVideoResponsive} type="video/mp4" />
            Tu navegador no admite la reproducción de video.
          </video>
        </div>
        
      </div> */}
    </>
  );
};

/* function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
      width,
      height,
  };
} */
/* function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
  );

  useEffect(() => {
      function handleResize() {
          setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
} */

const ContactView = () => {
  //const { width } = useWindowDimensions();

  return (
    <div >
      <div >
        <Navbar />
        <Contact />
        <Footer />
      </div>
    </div>
  )
}

const HouseView = () => {
  //const { width } = useWindowDimensions();

  return (
    <div >
      <div >
        <Navbar />
        <House />
        <HouseFull />
        <Footer />

      </div>
    </div>
  )
}

const AparmentView = () => {
  //const { width } = useWindowDimensions();

  return (
    <div >
      <div >
        <Navbar />
        <Apartament />
        <ApartamentFull />
        <Footer />

      </div>
    </div>
  )
}



const MapView = () => {
  //const { width } = useWindowDimensions();

  return (
    <div >
      <div >
        <Navbar />
        <Map />
        <MapFull />
        <Footer />

      </div>
    </div>
  )
}

const ContentHome = withRouter(() => <HOME />);
const ContentContact = withRouter(() => <ContactView />);
const HouseContact = withRouter(() => <HouseView />);
const AparmentContact = withRouter(() => <AparmentView />);
const MapContact = withRouter(() => <MapView />);

const App = () => (
  <Switch>
    <Route exact path='/' component={() => <ContentHome />} />
    <Route exact path='/contacto' component={() => <ContentContact />} />
    <Route exact path='/house' component={() => <HouseContact />} />
    <Route exact path='/apartamentos' component={() => <AparmentContact />} />
    <Route exact path='/ubicacion' component={() => <MapContact />} />
    <Redirect to='/' />
  </Switch>
)


export default App;